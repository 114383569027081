import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  LinearProgress,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import MobileLayout from "../MobileLayout";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setQuizChance, setQuizScore } from "../game/slice";
import { RootState } from "../redux/store";
import { useTranslation } from "react-i18next";
import breeferLogo from "../asset/logo/LOGO_ORANGE.svg";
import BreefReportService from "../game/BreefReportService";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import mixpanel from "../mixpanel/mixpanelWrapper";
import { getBreefSetting } from "../services/breefGameService";

interface Question {
  question: string;
  options: string[];
  answer: number;
}
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles: any = makeStyles((theme: { palette: { grey: any[] } }) => ({
  linearProgress: {
    border: "1px solid rgba(136, 136, 136, 0.8)",
    height: "8px",
    borderRadius: "5px", // Rounded corners
    backgroundColor: "white", // White background
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#EC6608", // Progress bar color
      borderRadius: "5px",
    },
    marginBottom: "1rem",
  },
  glowEffect: {
    boxShadow: "0 0 5px 0.5px #8DC63F",
  },
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

const Quiz: React.FC = () => {
  const totalScore = 0;
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const score = useSelector((state: RootState) => state.score.quizScore);
  const points = useSelector((state: RootState) => state.score.points);
  const duration = useSelector((state: RootState) => state.score.duration);
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const navigate = useNavigate();
  const classes = useStyles();
  const [chances, setChances] = useState(0);
  const [quizGameScore, setQuizGameScore] = useState(30000);
  const [startTime, setStartTime] = useState(Date.now());
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(null);
  const [timeRemaining, setTimeRemaining] = useState<number>(60);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [ans, setAns] = useState(0);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [timerExpired, setTimerExpired] = useState(false); // Track if timer has expired
  const [isQuizfinished, setIsQuizfinished] = useState<boolean>(false);
  const videoUrl: any = localStorage.getItem("videoUrl");
  const userId: any = localStorage.getItem("InvitedUserId");
  const tenantId: any = localStorage.getItem("tenantId");
  const breefDetails = useSelector((state: RootState) => state.score.breefDetails);
  const quizChances = useSelector((state: RootState) => state.score.quizChances);
  const [isTracked, setIsTracked] = useState(false);
  const [totalTimeTaken, setTotalTimeTaken] = useState<number>();
  const questions: Question[] = breefDetails.questions;
  const [breefSetting, setBreefSetting]: any = useState();

  useEffect(() => {
    if (!isTracked) {
      mixpanel.track("Quiz Page Viewed", {
        breef_id: breefDetails.id,
        tenant_id: tenantId,
        user_id: userId,
        number_of_qustions: questions.length,
        start_date: "",
        expiry_date: breefDetails.expiry,
        video_url: videoUrl,
        current_page: "quiz-info",
        game_name: breefDetails.gameId.gameName,
      });
      setIsTracked(true);
    }
  }, [isTracked]);

  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    async function fetchSetting() {
      const respone = await getBreefSetting();
      console.log("respone--", respone);

      setBreefSetting(respone?.results[0]);
      console.log("breefSetting", breefSetting);
      setQuizGameScore(respone?.results[0].startScore);
    }
    fetchSetting();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeRemaining > 0) {
        setTimeRemaining((time) => time - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeRemaining]);

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  useEffect(() => {
    if (timeRemaining === 0) {
      setTimerExpired(true); // Mark timer as expired
      handleNextQuestion();
    }
  }, [timeRemaining]);

  useEffect(() => {
    const handlecreateBreefReport = async () => {
      try {
        await BreefReportService.createBreefReport(
          Math.round(quizGameScore),
          duration,
          totalScore,
          quizChances,
          setSnackbarMessage,
          setSnackbarSeverity,
          setSnackbarOpen
        );
      } catch (error) {
        console.error("Error updating breef report:", error);
      }

      setTimeout(async () => {
        navigate("/game");
      }, 1000);

      try {
        await BreefReportService.updateBreefStatus();
      } catch (error) {
        setSnackbarMessage("Error updating breef status");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        console.error("Error updating breef status:", error);
      }
    };

    isQuizfinished && handlecreateBreefReport();
  }, [isQuizfinished]);

  const handleNextQuestion = async () => {
    setSelectedOptionIndex(null);
    setIsCorrect(null);
    setTimerExpired(false); // Reset timer expired state
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((index) => index + 1);
      setTimeRemaining(60); // Reset timer for the next question
      setStartTime(Date.now());
    } else {
      const roundedScore = Math.round(quizGameScore);
      console.log("Quiz Score: ", roundedScore);
      mixpanel.track("Answer selected", {
        breef_id: breefDetails.id,
        tenant_id: tenantId,
        user_id: userId,
        number_of_qustions: questions.length,
        start_date: breefDetails.period,
        expiry_date: breefDetails.expiry,
        video_url: videoUrl,
        current_page: "quiz",
        game_name: breefDetails.gameId.gameName,
        attempt_no: chances,
        time_taken_to_complete_quiz: totalTimeTaken,
        quiz_score: quizGameScore,
      });
      setIsQuizfinished(true);
      dispatch(setQuizScore(roundedScore));
      localStorage.setItem("quizScore", roundedScore.toString());
    }
  };

  const handleOptionClick = async (optionIndex: number) => {
    // if (selectedOptionIndex !== null) return; // Prevent multiple clicks
    setChances(chances + 1);

    const correct =
      questions[currentQuestionIndex]?.options[optionIndex] ===
      questions[currentQuestionIndex]?.answer.toString();
    setSelectedOptionIndex(optionIndex);
    setIsCorrect(correct);
    console.log("Chances: ", chances);

    const TimeTaken = (Date.now() - startTime) / 1000;
    const pointsDeducted = TimeTaken * breefSetting?.pointsPerSecond;
    setTotalTimeTaken(TimeTaken);
    setQuizGameScore(quizGameScore - pointsDeducted);
    console.log(`Time taken: ${TimeTaken} seconds, Points lost: ${pointsDeducted}`);

    if (correct) {
      if (chances === 0) {
        setQuizGameScore(quizGameScore + breefSetting?.pointsPerRightAnswer);
        console.log("Correct answer on first try, Points gained: 2500 ");
      }
      mixpanel.track("Quiz submitted", {
        breef_id: breefDetails.id,
        tenant_id: tenantId,
        user_id: userId,
        number_of_qustions: questions.length,
        start_date: breefDetails.period,
        expiry_date: breefDetails.expiry,
        video_url: videoUrl,
        current_page: "quiz",
        game_name: breefDetails.gameId.gameName,
        question: currentQuestion,
        is_correct_answer: `${correct ? "yes" : "no"}`,
        attempt_no: chances,
        time_taken_to_answer: TimeTaken,
        question_score: quizGameScore,
      });
      dispatch(setQuizChance(chances)); // Increment chances only when correct
      setAns(ans + 1);
      setTimeout(() => {
        handleNextQuestion();
      }, 1000);
    } else {
      setQuizGameScore(quizGameScore - breefSetting?.pointsPerWrongAnswer);
      console.log("Incorrect answer, Points lost: 1000 ");
    }
  };

  const currentQuestion = questions[currentQuestionIndex];
  const progress = ((60 - timeRemaining) / 60) * 100;

  return (
    <MobileLayout>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>{/* <img src={breeferLogo} alt="logo" height="80 rem" /> */}</Toolbar>
      </AppBar>
      {questions.length > 0 && (
        <>
          {" "}
          <div style={{ height: "80%", width: "100%" }}>
            {/* <Box mt={3}>
                    <Typography variant="h6" align="center" style={{ marginTop: '10%' }}>
                        <span style={{ color: '#E2DFD2' }}>lorem10dijwemiuwejiwejwidmodmweomweodiwecmdijcmedceifmeifecmeciemcie
                          
                        </span>
                    </Typography>
                </Box> */}
            <div
              style={{
                margin: "20% 10% 10%",
                display: "flex",
                maxWidth: "100%",
                justifyContent: "center",
                alignContent: "center",
                wordWrap: "normal",
                wordBreak: "break-word",
              }}
            >
              <span
                style={{
                  letterSpacing: "0.05em",
                  fontSize: "2rem",
                  fontWeight: "350",
                  color: "#000000",
                  textAlign: "center",
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  wordWrap: "normal",
                  wordBreak: "break-word",
                }}
              >
                {currentQuestion?.question}
              </span>
            </div>

            <Box style={{ margin: "15% 16% 0%" }}>
              {currentQuestion?.options.map((option, index) => (
                <Button
                  key={index}
                  fullWidth
                  variant={selectedOptionIndex === index ? "contained" : "outlined"}
                  color="primary"
                  onClick={() => {
                    handleOptionClick(index);
                  }}
                  style={{
                    marginBottom: "1rem",
                    borderRadius: "14px",
                    height: "3rem",
                    fontSize: "1rem",
                    padding: "1.7rem",
                    fontWeight: "900",
                    wordWrap: "break-word",
                    wordBreak: "break-word",
                    borderColor:
                      selectedOptionIndex !== null && index === currentQuestion.answer
                        ? "#8DC63F"
                        : "#000000",
                    backgroundColor:
                      selectedOptionIndex !== null
                        ? index === selectedOptionIndex
                          ? isCorrect
                            ? "#8DC63F"
                            : "#FF0000"
                          : ""
                        : "#FFFFFF", // default white background
                    color:
                      selectedOptionIndex !== null && index === selectedOptionIndex && isCorrect
                        ? "white"
                        : "#000000",
                    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.6)",
                  }}
                  disabled={timerExpired && selectedOptionIndex === null}
                  className={
                    index === currentQuestion.answer && isCorrect !== null ? classes.glowEffect : ""
                  }
                >
                  {option}
                </Button>
              ))}
            </Box>
            <Box style={{ margin: "18% 17% 0%" }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                className={classes.linearProgress}
                style={{ boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.6)" }}
              />
              <Typography
                variant="body2"
                align="center"
                style={{ fontSize: "2rem", fontWeight: "300" }}
              >{`${String(Math.floor(timeRemaining / 60)).padStart(2, "0")}:${String(
                timeRemaining % 60
              ).padStart(2, "0")}`}</Typography>
            </Box>
          </div>
          {/* <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert onClose={handleClose} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar> */}
        </>
      )}
    </MobileLayout>
  );
};

export default Quiz;
