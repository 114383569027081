import axios, { AxiosRequestConfig } from 'axios';

// Define the API base URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface RegistrationData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  tenantId: string;
  teamIds: string[];
}

export const registerUser = async (data: RegistrationData) => {
  try {
    console.log(data);
    const response = await axios.post(`${API_BASE_URL}/v1/auth/register`, data);
    
    const { access, refresh } = response.data.tokens;
    const userId = response.data.user.id;
    console.log("User ID: ", userId);
    console.log("Access Token: ",access, "Refresh Token:", refresh);
    // Store tokens in localStorage
    localStorage.setItem('userId', userId);
    localStorage.setItem('accessToken', access.token);
    localStorage.setItem('refreshToken', refresh.token);

    const inviteUserId = localStorage.getItem('InvitedUserId');

    const initialConfig: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${access.token}`,
        'Content-Type': 'application/json',
      },
    };
    const resp = await axios.put(`${API_BASE_URL}/v1/Breefs/update-userId/${inviteUserId}/${userId}`, {}, initialConfig);
    console.log("resp replacing user id: ", resp);
    
    return response.data;
  } catch (error) {
    // Handle the error appropriately
    console.error('Error registering user:', error);
    throw error;
  }
};
interface LoginData {
    email: string;
    password: string;
  }
  
  export const loginUser = async (data: LoginData) => {
    try {
      console.log(data);
      const response = await axios.post(`${API_BASE_URL}/v1/auth/login`, data);
      console.log("Data: ", response);
      
      const { access, refresh } = response.data.tokens;
      const userId = response.data.user.id;
      console.log('response', response.data);
      console.log("User ID: ", userId);
      console.log("Access Token: ",access, "Refresh Token:", refresh);
      // Store tokens in localStorage
      localStorage.setItem('userId', userId);
      localStorage.setItem('accessToken', access.token);
      localStorage.setItem('refreshToken', refresh.token);
      return response.data;
    } catch (error) {
      // Handle the error appropriately
      console.error('Error logging in user:', error);
      throw error;
    }
  };

  export const forgotPassword = async (email : string) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/v1/auth/forgot-password`, { email });
      return response.data;
    } catch (error) {
      console.error('Error sending reset password email:', error);
      throw error;
    }
  };

  export const signout = () => {
    localStorage.clear();
  };
  