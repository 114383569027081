import level1 from '../asset/images/lvl_01.png';
import level2 from '../asset/images/lvl_02.png';
import level3 from '../asset/images/lvl_03.png';

export interface RankImage {
  imageUrl: string|null;
}

export function getRankImage(totalScore: number): RankImage {
console.log("total socre--",totalScore );

  if (totalScore >= 171001) {
    return {imageUrl:level1};
  } else if (totalScore >= 162001) {
    return {imageUrl:level2};
  } else if (totalScore >= 153001) {
    return {imageUrl:level3};
  } else if (totalScore >= 144001) {
    return {imageUrl:level3};
  } else if (totalScore >= 135001) {
    return {imageUrl:level1};
  } else if (totalScore >= 123001) {
    return {imageUrl:level3};
  } else if (totalScore >= 114001) {
    return {imageUrl:level1};
  } else if (totalScore >= 105001) {
    return {imageUrl:level3};
  } else if (totalScore >= 96001) {
    return {imageUrl:level1};
  } else if (totalScore >= 87001) {
    return {imageUrl:level3};
  } else if (totalScore >= 78001) {
    return {imageUrl:level1};
  } else if (totalScore >= 72001) {
    return {imageUrl:level2};
  } else if (totalScore >= 66001) {
    return {imageUrl:level1};
  } else if (totalScore >= 60001) {
    return {imageUrl:level2};
  } else if (totalScore >= 54001) {
    return {imageUrl:level1};
  } else if (totalScore >= 45001) {
    return {imageUrl:level3};
  } else if (totalScore >= 39001) {
    return {imageUrl:level1};
  } else if (totalScore >= 33001) {
    return {imageUrl:level2};
  } else if (totalScore >= 27001) {
    return {imageUrl:level1};
  } else if (totalScore >= 21001) {
    return {imageUrl:level2};
  } else if (totalScore >= 12001) {
    return {imageUrl:level1};
  } else if (totalScore >= 9001) {
    return {imageUrl:level3};
  } else if (totalScore >= 6001) {
    return {imageUrl:level1};
  } else if (totalScore >= 3001) {
    return {imageUrl:level2};
  } else {
    return {imageUrl:level3};
  }
}
