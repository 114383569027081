import React, { useEffect, useState } from "react";
import MobileLayout from "../MobileLayout";
import { HomeCard } from "./HomeCard";
import Logo from "../asset/logo/LOGO_ORANGE.svg";
import frame1 from "../asset/home-section/frame1.png";
import frame2 from "../asset/home-section/frame2.png";
import {
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@material-ui/core";
import { Menu as MenuIcon, Close as CloseIcon } from "@material-ui/icons";
import profilePic from "../asset/characters/profile.png";
import "./home.css";
import { useNavigate } from "react-router";
import { signout } from "../login/RegisterService";
import Card from "./Card";
import Carousel from "react-material-ui-carousel";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { getPendingBreefs } from "../introduction/BreefService";
import { getUserRank } from "./services";
import { useTranslation } from "react-i18next";
import mixpanel from "../mixpanel/mixpanelWrapper";

interface PendingBreef {
  breefName: string;
  expiry: string; // Assuming it's a string representation of a date
  gameId: {
    gameName: string;
    categoryId: string;
    gameCode: string;
    id: string;
  };
  id: string;
  questions: any[]; // Define the actual type of questions if possible
  status: string;
  tenantId: {
    status: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    // Add more properties as needed
  };
  videoUrl: string;
}
export const HomeSection = () => {
  const [t, i18n] = useTranslation("global");
  const smFname = useSelector((state: RootState) => state.onboard.firstname);
  const smLname = useSelector((state: RootState) => state.onboard.lastname);
  const smEmail = useSelector((state: RootState) => state.onboard.email);
  const smPhone = useSelector((state: RootState) => state.onboard.phone);
  const smUserID = useSelector((state: RootState) => state.onboard.userID);
  const userId = localStorage.getItem("userId");
  const [isTracked, setIsTracked] = useState(false);

  console.log("SM: ", smFname, smLname, smEmail, smPhone, smUserID);

  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [pendingBreefs, setPendingBreefs] = useState<PendingBreef[]>([]);
  const [submittedBreefs, setSubmittedBreefs] = useState<PendingBreef[]>([]);
  useEffect(() => {
    if (!isTracked) {
      mixpanel.track("Dashboard Viewed", {
        current_page: "dashboard",
      });
      setIsTracked(true);
    }
  }, [isTracked]);

  const handleHomeCardClick = () => {
    console.log("Home Card Clicked");
    navigate("/breefInvite");
  };
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      (event as React.KeyboardEvent).type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    signout(); // Call the signout service

    mixpanel.reset();

    navigate("/sign-in"); // Redirect to the login page
  };
  const drawerList = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      className="drawer-list"
    >
      <List>
        <ListItem>
          <IconButton onClick={toggleDrawer(false)} style={{ marginLeft: "auto" }}>
            <CloseIcon />
          </IconButton>
        </ListItem>
        <ListItem
          button
          onClick={() => {
            navigate("/home");
          }}
        >
          <ListItemText primary={t("drawer.home")} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            mixpanel.track("My profile clicked", {
              current_page: "dashboard",
            });
            navigate("/profile");
          }}
        >
          <ListItemText primary={t("drawer.profile")} />
        </ListItem>
        <ListItem button>
          <ListItemText primary={t("drawer.settings")} />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemText primary={t("drawer.logout")} />
        </ListItem>
      </List>
    </div>
  );

  useEffect(() => {
    const fetchBreefs = async () => {
      if (userId) {
        try {
          const breefs = await getPendingBreefs({ userId });
          setPendingBreefs(breefs.pendingBreefs);
          setSubmittedBreefs(breefs.submittedBreefs);

          console.log("Breefs:", breefs);
        } catch (error: any) {
          console.error("Failed to fetch breefs details:", error.message);
        }
      }
    };
    if (userId) {
      fetchBreefs();
    }
  }, [userId]);

  const [rank, setRank] = useState("");
  const [perc, setPerc] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const resp = await getUserRank();
      console.log("resp of rank: ", resp);
      if (!resp?.rank) {
        setRank("N/A");
        setPerc("N/A");
        return;
      }
      // console.log("rank: ", resp.rank, "perc: ", resp.perc);
      setRank(resp.rank);
      setPerc(resp.perc);
    };
    fetchData();
  }, []);

  return (
    <MobileLayout>
      <AppBar
        position="sticky"
        style={{ backgroundColor: "transparent", boxShadow: "none", backdropFilter: "blur(10px)" }}
      >
        <Toolbar style={{ justifyContent: "space-between" }}>
          <IconButton
            edge="start"
            color="primary"
            aria-label="menu"
            style={{ marginRight: "10px" }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon style={{ color: "#000", fontSize: "2rem" }} />
          </IconButton>
          {/* <img src={Logo} alt="Logo" style={{ height: '100px', margin: '0 auto' }} /> */}
          <Avatar
            alt="Profile"
            src={profilePic}
            style={{ marginLeft: "auto", cursor: "pointer" }}
            onClick={() => {
              mixpanel.track("My profile clicked", {
                current_page: "dashboard",
              });
              navigate("/profile");
            }}
          />
        </Toolbar>
      </AppBar>

      <div className="home-container overflow-auto">
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerList()}
        </Drawer>
        <div className="center-row">
          {/* <img src={caresol} alt="" /> */}
          <Box style={{ padding: "0rem 1.5rem", width: "100%", height: "10rem" }}>
            <Carousel
              index={0}
              autoPlay={false}
              navButtonsAlwaysInvisible
              indicators={false}
              animation="slide"
            >
              <Card rank={rank} perc={perc} />
            </Carousel>
          </Box>
        </div>
        <div style={{ margin: "0 30px" }}>
          <div className="breef-heading">
            <div>
              <h5>{t("home.pbreefs")}</h5>
            </div>
            <button
              style={{ border: "none", background: "none", padding: 0 }}
              onClick={() => {
                navigate("/view");
              }}
            >
              <p style={{ color: "#EB6608" }}>{t("home.viewall")}</p>
            </button>
          </div>
        </div>
        <div className="brief-container">
          {pendingBreefs &&
            pendingBreefs.slice(0, 2).map((breef) => (
              <HomeCard
                key={breef.id}
                image={frame1}
                title={breef.breefName}
                onClick={() => {
                  mixpanel.track("Breef opened", {
                    breef_id: breef.id,
                    current_page: "dashboard",
                  });
                  navigate("/breefInvite", { state: { breef } });
                }}
              />
            ))}
        </div>

        <div style={{ margin: "0 30px" }}>
          <div className="breef-heading">
            <div>
              <h5>{t("home.sbreefs")}</h5>
            </div>
            <button
              style={{ border: "none", background: "none", padding: 0 }}
              onClick={() => {
                navigate("/view");
              }}
            >
              <p style={{ color: "#EB6608" }}>{t("home.viewall")}</p>
            </button>
          </div>
        </div>

        <div className="brief-container" style={{ overflow: "auto" }}>
          {submittedBreefs &&
            submittedBreefs.slice(0, 2).map((breef) => (
              <HomeCard
                key={breef.id}
                image={frame2}
                title={breef.breefName}
                onClick={() => {
                  mixpanel.track("Breef opened", {
                    breef_id: breef.id,
                    current_page: "dashboard",
                  });
                  navigate("/breefInvite", { state: { breef } });
                }}
              />
            ))}
        </div>
      </div>
    </MobileLayout>
  );
};
